import React from 'react'
import './Widgets.css';
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function Widgets() {

    const newsArticle = (heading, subtitle) => (
        <div className="widgets__article">
            <div className="widgets__articleLeft">
                <FiberManualRecordIcon/>
            </div>
            <div className="widgets__articleRight">
                <h4>{heading}</h4>
                <p>{ subtitle}</p>
            </div>
        </div>
    )

    return (
        <div className="widgets">
            <div className="widgets__header">
                <h2> News</h2>
                <InfoIcon />
            </div>

            {newsArticle("Ethiopian News ", "Grand Ethiopian Renaissance Dam")}
            {newsArticle("Corona Virus", "Vaccination Rate")}
            {newsArticle("Great Ethiopian Run ", "Many people Particpate")}
            

        </div>





    );
}

export default Widgets;
