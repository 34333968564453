import React from 'react'
import './InputOption.css';

function InputOption({ Icon, title, color }) {
    return (
        <div className="inputOption">
            <Icon style={{ color: color }} />
            <h7>{ title}</h7>
        </div>
    )
}

export default InputOption
