import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAd1lJDnQABJap-nyw9jlieILAvCkKw-zM",
    authDomain: "habeshalinkedin.firebaseapp.com",
    projectId: "habeshalinkedin",
    storageBucket: "habeshalinkedin.appspot.com",
    messagingSenderId: "12393359521",
    appId: "1:12393359521:web:360fc7bf781acd16f328e0",
    measurementId: "G-GY086K1894"
  };
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const auth = firebaseApp.auth();

export { db, auth };